import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-red-button',
  templateUrl: './red-button.component.html',
  styleUrls: ['./red-button.component.less']
})
export class RedButtonComponent {
  @Input()
  public reverse = false;

  @Input()
  public text: string;
}
